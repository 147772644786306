<template>
  <div>
    <v-card class="mb-2">
      <v-card-title>
        <span class="headline" v-html="getTitle()"></span>
        <v-spacer></v-spacer>
        <v-chip class="ma-2" color="grey darken-3" v-if="hasBundleType">
          {{ bundleTypeObj.name }}
        </v-chip>
      </v-card-title>
      <v-card-text>
        <template v-if="showTypeSelect && !hasBundleType">
          <div class="text-wrap subtitle-1">Please select bundle type</div>

          <v-item-group>
            <v-container>
              <v-row>
                <v-col
                  v-for="type in bundleTypes"
                  :key="`licence-type-${type._key}`"
                  cols="12"
                  md="4"
                >
                  <!-- eslint-disable-next-line -->
                  <v-item v-slot:default="{ active, toggle }">
                    <v-card
                      :color="'grey darken-3'"
                      class="d-flex align-center"
                      dark
                      height="180"
                      @click="setBundleType(type._key)"
                    >
                      <v-scroll-y-transition>
                        <div
                          v-if="!active"
                          class="display-1 flex-grow-1 text-center"
                        >
                          {{ type.name }}
                        </div>
                      </v-scroll-y-transition>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>
        </template>

        <v-form data-vv-scope="form-bundle" key="bundleCreateUpdate">
          <component v-bind:is="bundleTypeForm" ref="refFormBundle"></component>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="error" text to="/store/bundles-list">Close</v-btn>
        <v-btn v-if="hasBundleType" color="success" text @click.stop="save()"
          >Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import GeneralMixin from "@/mixins/general.js"
import InternalForm from "./InternalForm"
import ExternalForm from "./ExternalForm"
import EventForm from "./EventForm"

export default {
  props: {
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
  },

  components: {
    InternalForm,
    ExternalForm,
    EventForm,
  },

  mixins: [GeneralMixin],

  data() {
    return {}
  },

  provide() {
    return {
      $validator: this.$validator,
    }
  },

  created: function () {
    let self = this
    self.$store.dispatch("saveLoaderAction", true)
    self.$store.dispatch("resetBundleItem").then(() => {
      self.getItemData().then(() => {
        self.$store.dispatch("setBundleTypes").then(() => {
          self.$store.dispatch("saveLoaderAction", false)
        })
      })
    })
  },

  methods: {
    getTitle() {
      return this.editedIndex === -1
        ? "Create new bundle"
        : `<span class="blue-grey--text text--lighten-4">Edit bundle</span> ${this.activeBundle.name}`
    },
    getItemData() {
      return this.editedIndex !== -1
        ? this.$store.dispatch("getBundleItem", {
            slug: this.editedIndex,
          })
        : Promise.resolve()
    },
    createBundle(formData) {
      let self = this
      self.$store
        .dispatch("createBundle", formData)
        .then(({ data }) => {
          let params = {
            text: `Bundle <strong>${formData.data.name}</strong> was created successfully.`,
            show: true,
          }
          self.close().then(() => {
            self.$root.$emit("snackbar", params)
          })
        })
        .catch(({ response }) => {
          self.handleValidationErrors(response, "form-bundle")
        })
    },
    editBundle(formData) {
      let self = this
      self.$store
        .dispatch("editBundle", formData)
        .then(({ data }) => {
          let params = {
            text: `Bundle <strong>${formData.data.name}</strong> was edited successfully.`,
            show: true,
          }
          self.close().then(() => {
            self.$root.$emit("snackbar", params)
          })
        })
        .catch(({ response }) => {
          self.handleValidationErrors(response, "form-bundle")
        })
    },
    save() {
      let self = this
      self.$refs.refFormBundle.$validator.errors.clear("form-bundle")
      self.$refs.refFormBundle.$validator
        .validateAll("form-bundle")
        .then((result) => {
          if (result) {
            let formData = {
              data: {
                name: self.activeBundle.name,
                expiration: parseInt(self.activeBundle.expiration),
                description: self.activeBundle.description,
                connections: parseInt(self.activeBundle.connections),
                is_active: self.activeBundle.is_active,
                type_key: self.activeBundle.type_key,
              },
            }
            if (this.editedIndex > -1) {
              formData["slug"] = self.editedIndex
              self.editBundle(formData)
            } else {
              self.createBundle(formData)
            }
          }
        })
    },
    close() {
      return new Promise((resolve, reject) => {
        this.$router
          .push({ path: `/store/bundles-list/` })
          .then(() => {
            resolve()
          })
          .catch((err) => {})
      })
    },
    setBundleType(type) {
      this.$store.dispatch("setBundleType", type)
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      activeBundle: "activeBundle",
      bundleTypes: "bundleTypes",
    }),

    hasBundleType: function () {
      return !!this.activeBundle.type_key
    },

    bundleTypeForm: function () {
      let typeName = this.bundleTypeObj?.name
      if (typeName === "External Event") {
        return "EventForm"
      }
      return typeName ? `${typeName}Form` : ""
    },

    bundleTypeObj: function () {
      return this.bundleTypes.find((l) => l._key === this.activeBundle.type_key)
    },

    showTypeSelect: function () {
      return this.editedIndex === -1 || this.activeBundle._key
    },
  },
}
</script>
