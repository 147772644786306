<template>
  <v-form data-vv-scope="form-bundle" key="bundleCreateUpdate">
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          label="Name"
          v-model="activeBundle.name"
          :error-messages="errors.collect('form-bundle.name')"
          data-vv-name="name"
          data-vv-validate-on="change"
          v-validate="'required'"
          outlined
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <v-row class="d-flex align-start">
          <v-col cols="12" sm="12" class="d-flex justify-center">
            <v-switch
              v-model="activeBundle.is_active"
              inset
              label="Is active"
              class="align-self-top mt-0"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
          label="Duration"
          v-model="activeBundle.expiration"
          :error-messages="errors.collect('form-bundle.expiration')"
          data-vv-name="expiration"
          data-vv-validate-on="change"
          v-validate="'required|numeric'"
          outlined
          type="number"
          :min="0"
          suffix="months"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
          label="Number connections"
          v-model="activeBundle.connections"
          :error-messages="errors.collect('form-bundle.connections')"
          data-vv-name="connections"
          data-vv-validate-on="change"
          v-validate="'required|numeric'"
          outlined
          type="number"
          :min="0"
          suffix="connections"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="12">
        <v-textarea
          v-model="activeBundle.description"
          label="Description"
          outlined
          :error-messages="errors.collect('form-bundle.description')"
          data-vv-name="description"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  inject: ["$validator"],

  computed: {
    ...mapGetters({
      activeBundle: "activeBundle",
    }),
  },
}
</script>
